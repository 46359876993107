var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 767;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得

  var fixheadH = $(".header").height();
  $(".header").next().css('padding-top',fixheadH);

  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});
  $(".widelink--sp").on('click',function(){
    if (default_width <= 920) {
      window.location=$(this).find("a").attr("href");
      return false;
    }
	});
  $(".widelink--pc").on('click',function(){
    if (default_width >= 920) {
      window.location=$(this).find("a").attr("href");
      return false;
    }
	});

  /*------------------------*/
  //ページトップ
  /*------------------------*/
	var topBtn = $('.pageTop');
  topBtn.hide();
	// スクロールが200に達したらボタン表示
	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
				topBtn.fadeIn();
		} else if ($(this).scrollTop() < 200) {
				topBtn.fadeOut();
		} else {
      topBtn.fadeIn();
    }
	});
	// スクロールしてトップ
	topBtn.on('click',function () {
		$('body,html').animate({
				scrollTop: 0
		}, 500);
		return false;
  });

  //スムーススクロール（ページ内リンク）
  $('a[href^="#"],.js-scroll').on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var d = g.offset().top;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //tab
  $('.tabMenu .tabMenu__item').on('click',function() {
    var i = $(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $(this).parent().next(".tabItem").children(".tabList").hide().removeClass("show").eq(i).fadeIn(300).addClass("show");
  });

  //URL取得とチェック
  var url = location.href;
  if (url.match(/\?id=shiga/)) {
    //コンテンツ非表示&タブを非アクティブ
    $('.tabItem .tabList').removeClass('show');
    $('.tabMenu .tabMenu__item').removeClass('active');
    //滋賀コンテンツ表示
    $('.tabItem').find('.shiga').addClass('show')
    //滋賀タブのアクティブ化
    $('.tabMenu').find('.shiga').addClass('active');
  }

  //accordion
  $(".js-accordion .js-accordion__btn").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-accordion__item").slideToggle();
  });

  //pulldown
  $(".js-pulldown .js-pullDownList").on('click',function() {
    $(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //目次
  if ($(".tocBox__inner").children(".tocList").children(".tocList__item").length >= 4) {
    var flg = 'closed'; //デフォルトの設定：表示='opened'、非表示='closed'
    　$('.tocBox__inner').append('<p class="tocMore">目次を開く</p>'); //デフォルトの開閉リンク文字列
    $('.tocMore').on('click', function() {
      $(this).parents('.tocBox').toggleClass('open');
      if(flg == 'closed'){
        $(this).text('目次を閉じる'); //表示状態のリンク文字列
        flg = 'opened';
      }else{
        $(this).text('目次を開く'); //非表示の状態のリンク文字列
        flg = 'closed';
      }
    });
  } else if ($(".tocBox__inner").children(".tocList").children(".tocList__item").length >= 1 && $(".tocBox__inner").children(".tocList").children(".tocList__item").find(".tocList__item").length >= 4) {
    var flg = 'closed'; //デフォルトの設定：表示='opened'、非表示='closed'
    　$('.tocBox__inner').append('<p class="tocMore">目次を開く</p>'); //デフォルトの開閉リンク文字列
    $('.tocMore').on('click', function() {
      $(this).parents('.tocBox').toggleClass('open');
      if(flg == 'closed'){
        $(this).text('目次を閉じる'); //表示状態のリンク文字列
        flg = 'opened';
      }else{
        $(this).text('目次を開く'); //非表示の状態のリンク文字列
        flg = 'closed';
      }
    });
  } else {
    $('.tocMore').on('click', function() {
      $('.tocBox').addClass('open');
    });
  }

  //追従バナー
  // var fixB = $(".fixBnr");
  // if (fixB.length) {
  //   $(window).on('scroll',function () {
  //     var doch = $(document).innerHeight(), //ページ全体の高さ
  //     winh = $(window).innerHeight(), //ウィンドウの高さ
  //     bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
  //     footh = $(".footer").innerHeight();
  //     if ($(this).scrollTop() > 100 && $(this).scrollTop() < bottom - footh) {
  //       fixB.addClass("show");
  //     } else {
  //       fixB.removeClass("show");
  //     }
  //   });
  // }

  var fixB = $(".fixBnr");
  if (fixB.length) {
    $(window).on('scroll',function () {
      if (default_width >= break_point) {
        var doch = $(document).innerHeight(), //ページ全体の高さ
        winh = $(window).innerHeight(), //ウィンドウの高さ
        bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
        footh = $(".footer").innerHeight();
        if ($(this).scrollTop() > 100 && $(this).scrollTop() < bottom - footh) {
          fixB.addClass("show");
        } else {
          fixB.removeClass("show");
        }
      }
    });
  }

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

  /*------------------------*/
  //バーガーメニュー表示・非表示
  /*------------------------*/
  var burgerBtn = $('.burger');
  var headerHeight = $('.header').innerHeight();
  var headerInnerHeight = $('.header__inner').innerHeight();
  // スクロールがヘッダーの高さに達したらボタン表示
  $(window).scroll(function () {
    if (default_width <= 920 && $(this).scrollTop() > headerHeight) {
      burgerBtn.addClass("show");
    } else if (default_width <= 920 && $(this).scrollTop() < headerHeight) {
      if (!burgerBtn.hasClass("is-open")) {
        burgerBtn.removeClass("show");
      }
    } else {
      if (!burgerBtn.hasClass("is-open")) {
        burgerBtn.removeClass("show");
      }
    }
    if (default_width <= 920 && $(this).scrollTop() > headerHeight + 200) {
      $('.header').addClass("show");
    } else if (default_width <= 920 && $(this).scrollTop() < headerHeight + 200) {
      if (!burgerBtn.hasClass("is-open")) {
        $('.header').removeClass("show");
      }
    } else {
      if (!burgerBtn.hasClass("is-open")) {
        $('.header').removeClass("show");
      }
    }
    //PCでグロナビ部分だけ追従させる仕組み
    // if (default_width >= 920 && $(this).scrollTop() > headerInnerHeight) {
    //   $(".gNav").addClass("fixedNav");
    // } else if (default_width >= 920 && $(this).scrollTop() < headerInnerHeight) {
    //   if ($(".gNav").hasClass("fixedNav")) {
    //     $(".gNav").removeClass("fixedNav");
    //   }
    // } else {
    //   if ($(".gNav").hasClass("fixedNav")) {
    //     $(".gNav").removeClass("fixedNav");
    //   }
    // }
  });
	//バーガーボタンクリック
	burgerBtn.on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= 920) {
      burgerBtn.toggleClass("is-open");
      if ($(".gNav").hasClass("show")) {
        $(".gNav").removeClass("show").addClass("hide");
      } else if ($(".gNav").hasClass("hide")) {
        $(".gNav").removeClass("hide").addClass("show");
      } else {
        $(".gNav").addClass("show");
      }
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").height(window.innerHeight).removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= 920) {
  		$(".gNav").removeClass("show").addClass("hide");
  		burgerBtn.removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
	});
  
	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".gNav").removeClass("show hide");
				burgerBtn.removeClass("is-open");
				$(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".gNav").removeClass("show hide");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= 920) { //ヘッダーSPデザイン
        fixheadH = $(".header").height();
        $(".header").next().css('padding-top',fixheadH);
			} else if (resize_width <= break_point) {
        /*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        // $header.removeClass(scrollClass);
      } else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
        fixheadH = $(".header").height();
        $(".header").next().css('padding-top',fixheadH);
			}
		}, 200);
	});
});